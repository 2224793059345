import React from 'react';
import { Form } from 'semantic-ui-react';
import { cartSelector } from '@/store/selector';
import { useDispatch } from 'react-redux';
import { setPayment, setCustomer } from '@/components/cartSlice';
import { CardElement } from '@stripe/react-stripe-js';
import Colors from '@/constants/colors';
import CartHelper from '@/helpers/cartHelper';

const cardStyle = {
  style: {
    base: {
      color: Colors.blackTwo,
      fontFamily: 'Arial,Helvetica,sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '1.2rem',
      '::placeholder': {
        color: Colors.grayThree,
      },
    },
    invalid: {
      // fontFamily: 'Arial, sans-serif',
      color: Colors.redOne,
      iconColor: Colors.redOne,
    },
  },
};

const PaymentForm = ({ classes, isReservationGroupBooking = false }) => {
  const dispatch = useDispatch();
  const cart = cartSelector();
  const customer = cart.customer;
  const cartPayment = cart.payment;
  // const [guestPayment, setGuestPayment] = useState({ ...cartPayment });
  const { due } = CartHelper.cartSummary(cart, isReservationGroupBooking);
  const handleChange = async (event) => {
    // console.log(event);
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
    // setDisabled(event.empty);
    // setError(event.error ? event.error.message : "");
    if (!event.error && event.complete) {
      dispatch(setPayment({ paymentFulfilled: true }));
      dispatch(setCustomer({ ...customer, postalCode: event.value.postalCode }));
    }
    if ((event.error || !event.complete) && cartPayment.paymentFulfilled) {
      dispatch(setPayment({ paymentFulfilled: false }));
    }
  };

  /*const onChange = (_event, { id, value }) => {
    setErrors({ ...errors, [id]: null });
    setGuestPayment({ ...guestPayment, [id]: value });
  };

  const onBlur = () => {
    ValidationHelper.validateSchema(profileSchema, guestPayment)
      .then(() => {
        dispatch(setPayment({ ...guestPayment }));
      })
      .catch(setErrors);
  };*/

  return (
    <>
      {due > 0 && (
        <Form className={classes.root}>
          <div className={classes.header}>
            <h2>Payment Method</h2>
          </div>
          <Form.Group widths="equal">
            <Form.Field>
              <label htmlFor="card-element">Card Information</label>
              {/* <CardElement id="card-element" options={cardStyle} onChange={handleChange} /> */}
              <CardElement id="card-element" options={cardStyle} onChange={handleChange} />
            </Form.Field>
          </Form.Group>
        </Form>
      )}
    </>
  );
};

export default React.memo(PaymentForm);
