import React from 'react';
import { createUseStyles } from 'react-jss';
import Colors from '@/constants/colors';
import { cartSelector } from '@/store/selector';
import CartHelper from '@/helpers/cartHelper';

const useStyles = createUseStyles(
  {
    root: {
      // borderBottom: `1px solid ${Colors.blackOne}`,
      borderTop: `1px solid ${Colors.blackOne}`,
      display: 'flex',
      '& div:first-child': {
        borderRight: `1px solid ${Colors.blackOne}`,
        borderLeft: `1px solid ${Colors.blackOne}`,
      },
    },
    totalItem: {
      flex: '1 1 50%',
      height: '60px',
      position: 'relative',
      padding: '0 60px',
      left: '-1px',
      top: '-1px',
      // borderBottom: `1px solid ${Colors.blackOne}`,
    },
    totalText: {
      // font-family: "Work-Sans", sans-serif;
      fontSize: '1rem',
      letterSpacing: '1.4px',
      lineHeight: '62px',
      margin: 0,
      textTransform: 'uppercase',
    },
    totalNumber: {
      // font-family: "Work-Sans", sans-serif;
      fontSize: '1rem',
      lineHeight: '62px',
      margin: 0,
      top: 0,
      position: 'absolute',
      right: '60px',
    },
    giftCard: {
      color: 'green',
    },
    [`@media (max-width: 1200px)`]: {
      totalText: {
        fontSize: '12px !important',
        lineHeight: '30px !important',
      },
      totalItem: {
        padding: '0 25px !important',
        textAlign: 'center !important',
        height: '48px !important',
      },
      totalNumber: {
        position: 'initial !important',
        margin: '-28px !important',
      },
    },
  },
  { name: 'CartTotal' },
);

const CartTotal = ({ isReservationGroupBooking = false }) => {
  const classes = useStyles();
  const cart = cartSelector();

  const { roomQty, total, due, giftCard, giftCardAmountApplied } = CartHelper.cartSummary(
    cart,
    isReservationGroupBooking,
  );

  return (
    <div className={classes.root}>
      <div className={classes.totalItem}>
        <p className={classes.totalText}>{roomQty} ROOM TOTAL</p>
        <p className={classes.totalNumber}>
          ${total}{' '}
          {giftCard !== 0 && <span className={classes.giftCard}> - ${giftCardAmountApplied}</span>}
        </p>
      </div>
      <div className={classes.totalItem}>
        <p className={classes.totalText}>DUE NOW</p>
        <p className={classes.totalNumber}>${due}</p>
      </div>
    </div>
  );
};

export default React.memo(CartTotal);
