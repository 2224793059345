import React from 'react';
import { createUseStyles } from 'react-jss';
import GuestForm from './GuestForm';
import PaymentForm from './PaymentForm';

const useStyles = createUseStyles(
  {
    root: {},
    header: {
      position: 'relative',
      marginBottom: '15px',
      '& > h2': {
        display: 'inline-block',
        fontSize: '1.2rem',
        lineHeight: '25px',
        // marginLeft: '18px',
        width: 'calc(100% - 50px)',
      },
    },
    separator: {
      marginTop: '30px',
      marginBottom: '30px',
    },
  },
  { name: 'LeftCheckoutPanel' },
);

const LeftCheckoutPanel = ({ isReservationGroupBooking = false }) => {
  const classes = useStyles();

  return (
    <>
      <GuestForm
        classes={classes}
        isReservationGroupBooking={isReservationGroupBooking}
      ></GuestForm>
      <hr className={classes.separator}></hr>
      <PaymentForm
        classes={classes}
        isReservationGroupBooking={isReservationGroupBooking}
      ></PaymentForm>
    </>
  );
};

export default React.memo(LeftCheckoutPanel);
