import './CheckoutSuccessView.css';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import BookingEngineLayout from '@/layout/BookingEngineLayout';
import { cartSelector } from '@/store/selector';
import car from '@/assets/images/car1_lg.png';
import useSuccessViewEmptyConfirmation from '@/hooks/useSuccessViewEmptyConfirmation';
import { resetCart } from '@/components/cartSlice';
import Top from '@/components/common/Top';
import CartItemList from '@/components/checkout/CartItemList';

const CheckoutSuccessView = () => {
  useSuccessViewEmptyConfirmation();
  const cart = cartSelector();
  const reservationConfirmation = cart.reservationConfirmation;
  const isReservationGroupBooking = cart.reservationConfirmation.reservationGroupBooking;
  const customer = cart.customer;
  const delay = 4000; // length per step
  const [step, setNextSep] = useState(0);
  const carStepClass = ['', 'running', 'stall', 'drive', ''];
  const dispatch = useDispatch();

  useEffect(() => {
    setTimeout(function () {
      setNextSep(1);
      setTimeout(function () {
        setNextSep(2);
        setTimeout(function () {
          setNextSep(3);
        }, delay / 4);
      }, delay / 4);
    }, delay / 4);
    return () => {
      dispatch(resetCart());
    };
  }, []);

  return (
    <BookingEngineLayout showCustomTop={false}>
      <Top showMenu={false}></Top>
      <hr></hr>
      <div className={`group vroomvroom ${carStepClass[step]}`}>
        <div className="group set-color">
          <div className="color"></div>
          <div className="color"></div>
        </div>

        <div className="cars">
          <img src={car} alt="car" className="active" />
        </div>
      </div>
      {step == 3 && (
        <div className="thank-you">
          <div className="group thank-you-wrapper">
            <div className="container group">
              <div className="two-col group">
                <div className="block group">
                  <p className="headline">Booking Complete</p>
                  <h1 className="headlineDescription">
                    Get ready {customer.firstName}, you are going to {reservationConfirmation.city}.
                  </h1>
                  {!isReservationGroupBooking && (
                    <p className="confirmation-num">
                      Your Confirmation Number is {reservationConfirmation.reservationNumber}{' '}
                    </p>
                  )}
                  <div className="break"></div>
                  <p className="policy">A copy of your reservation will be sent to your email.</p>
                  <p className="policy">
                    Cancellation Policy: {reservationConfirmation.cancellationPolicy}
                  </p>
                </div>
                <div className="block group">
                  <p className="headlineInformation">Booking Information</p>
                  <div className="scrollable">
                    <CartItemList
                      readOnly={true}
                      isReservationGroupBooking={isReservationGroupBooking}
                    ></CartItemList>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </BookingEngineLayout>
  );
};

export default React.memo(CheckoutSuccessView);
