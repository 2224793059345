import '@/App.css';
import { Route, BrowserRouter } from 'react-router-dom';
import CompanyView from './views/AvailabilityView/CompanyView';
import CheckoutSuccessView from './views/CheckoutView/CheckoutSuccessView';
import PropertyView from './views/AvailabilityView/PropertyView';
import ErrorPageView from './views/ErrorPageView';
import ImageTestView from './views/ImageTestView';
import GroupView from './views/ReservationGroup/GroupView';
import CustomRoutes from '@/components/CustomRoutes';

function App() {
  return (
    <BrowserRouter>
      <CustomRoutes>
        <Route path="/availability">
          <Route path="company" element={<CompanyView />} />
          <Route path="property" element={<PropertyView />} />
        </Route>
        <Route path="checkout/success" element={<CheckoutSuccessView />} />
        <Route path="/error" element={<ErrorPageView />} />
        <Route path="/image-test" element={<ImageTestView />} />
        <Route path="/:friendlyUrl/groups/:groupUrl" element={<GroupView />} />
        <Route path="/:friendlyUrl" element={<PropertyView />} />
      </CustomRoutes>
    </BrowserRouter>
  );
}

export default App;
