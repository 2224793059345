import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import BookingEngineLayout from '@/layout/BookingEngineLayout';
import Policies from '@/components/common/Policies';
import MenuResponsive from '@/components/common/MenuResponsive';
import Top from '@/components/common/Top';
import Footer from '@/components/common/Footer';
import InfoPanel from '@/components/common/InfoPanel';
import { createUseStyles } from 'react-jss';
import '@/assets/flickity.css';
import './GroupView.css';
import Login from './components/Login';
import ReservationGroupInfor from './components/ReservationGroupInfor';
import { getPropertyData, getPropertyIdByFriendlyUrl } from '@/api/propertyAPI';
import { Loader } from 'semantic-ui-react';
import { useParams } from 'react-router-dom';
import { getSession } from '@/api/sessionAPI';
import { setSession } from '@/components/sessionSlice';
import { sessionSelector } from '@/store/selector';
import { setCompanyProperty } from '@/components/companySlice';
import { resetCart } from '@/components/cartSlice';
import { menuSelector } from '@/store/selector';
import { MENU_ITEMS } from 'utils/constants';
import HTMLHelper from '@/helpers/htmlHelper';
import { selectItem } from '@/components/menuSlice';

const useStyles = createUseStyles(
  {
    root: {
      width: '100%',
      overflowY: 'auto',
    },
    back: (properties) => ({
      backgroundColor: properties.propertyColor,
      padding: '100px',
      maxHeight: '700px',
      marginTop: '50px',
      marginBottom: '100px',
      marginLeft: '200px',
      marginRight: '200px',
      color: properties.fontColor + ' !important;',
      '& > a': {
        color: properties.fontColor + ' !important;',
      },
    }),
    [`@media (max-width: 1200px)`]: {
      back: {
        padding: '50px !important',
        marginBottom: '0px !important',
        marginLeft: '0px !important',
        marginRight: '0px !important',
      },
    },
  },
  { name: 'PropertyView' },
);

const PropertyView = () => {
  const [backClolor, setBackClolor] = useState({});
  const [fontColor, setFontColor] = useState({});
  const [loading, setLoading] = useState({ loading: true });
  const [property, setProperty] = useState(null);
  const [groupBooking, setGroupBooking] = useState(null);

  const contactSection = useRef(null);
  const classes = useStyles({ propertyColor: backClolor, fontColor: fontColor });
  const params = useParams();
  const dispatch = useDispatch();
  const session = sessionSelector();
  const menu = menuSelector();

  function propertyData(propertyId) {
    getPropertyData({
      propertyId: propertyId,
    })
      .then((response) => {
        dispatch(setCompanyProperty(response.data));
        setProperty(response.data);
        setBackClolor(response.data.color);
        setFontColor(response.data.fontColor);
        setLoading(false);
      })
      .catch(() => {});
  }

  useEffect(() => {
    if (session?.token) {
      if (params?.friendlyUrl) {
        getPropertyIdByFriendlyUrl({ friendlyUrl: params.friendlyUrl })
          .then((response) => {
            propertyData(response.data);
          })
          .catch(() => {});
      }
    }
  }, [session]);

  useEffect(() => {
    dispatch(resetCart());
    getSession().then((response) => {
      dispatch(setSession(response.data.token));
    });
  }, []);

  useEffect(() => {
    if (menu.item === MENU_ITEMS.CONTACT) {
      HTMLHelper.scrollDown(contactSection);
    }
    dispatch(
      selectItem({
        item: null,
      }),
    );
  }, [menu.item]);

  const onLoginSuccess = (data) => {
    setGroupBooking(data);
  };

  const onGiftCardMenuSelected = () => {};

  return (
    <BookingEngineLayout showCustomTop={true}>
      {loading && (
        <div className="dimmer">
          <Loader id="full-screen-loader" size="big" indeterminate>
            Loading page...
          </Loader>
        </div>
      )}
      {property && (
        <>
          <div className={classes.root}>
            {
              <Top
                showMenu={true}
                isReservationGroupBooking={true}
                onGiftCardMenuSelected={onGiftCardMenuSelected}
              ></Top>
            }

            {!groupBooking && <Login onLoginSuccess={onLoginSuccess} />}
            {groupBooking && (
              <ReservationGroupInfor
                title={groupBooking.welcomeTitle}
                subtitle={groupBooking.subtitle}
                eventDetails={groupBooking.eventDetails}
                rooms={groupBooking.bookingSearchByDatesResponse.itemList}
              />
            )}

            <div className={classes.back} ref={contactSection}>
              {property.contact != null && <InfoPanel property={property}></InfoPanel>}
            </div>

            <Footer property={property}></Footer>
          </div>
          <Policies property={property}></Policies>
          <MenuResponsive
            property={property}
            isReservationGroupBooking={true}
            onGiftCardMenuSelected={onGiftCardMenuSelected}
          ></MenuResponsive>
        </>
      )}
    </BookingEngineLayout>
  );
};

export default React.memo(PropertyView);
