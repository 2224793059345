import React from 'react';
import { createUseStyles } from 'react-jss';
import { Container, Grid, GridColumn } from 'semantic-ui-react';
import RoomTypeItem from '@/components/common/RoomTypeItem';
import CheckOutBar from '@/components/checkout/CheckOutBar';

const useStyles = createUseStyles(
  {
    content: { display: 'inline-block', marginTop: '10px' },
    title: {
      fontSize: '3rem',
      textAlign: 'center',
      marginTop: '3rem',
      marginBottom: '3rem',
    },
    eventDetails: {
      marginBottom: '15px',
    },
  },
  { name: 'ReservationGroupInfor' },
);

const ReservationGroupInfor = ({ title, subtitle, eventDetails, rooms, defaultRoomTypeImage }) => {
  const classes = useStyles();

  const handleItemSelected = () => {};

  return (
    <div>
      <div className={classes.title}>{title}</div>
      <Container>
        <Grid columns={1} centered>
          <GridColumn textAlign="center">
            <font size="5">{subtitle}</font>
          </GridColumn>
        </Grid>
        <Grid columns={1} centered className={classes.eventDetails}>
          <GridColumn textAlign="center">
            <div dangerouslySetInnerHTML={{ __html: eventDetails }}></div>
          </GridColumn>
        </Grid>
      </Container>
      <Grid verticalAlign="middle" columns={1} stackable>
        <GridColumn>
          <div className="result-available-property">Rooms available in your group</div>
        </GridColumn>
      </Grid>
      <div className={classes.content}>
        {rooms?.length > 0 &&
          rooms.map((bookingSearchByDatesResponseItem, idx) => (
            <RoomTypeItem
              // key={bookingSearchByDatesResponseItem.roomType.id}
              key={idx}
              bookingSearchByDatesResponseItem={{
                ...bookingSearchByDatesResponseItem,
                people: 2,
              }}
              bestAvailableRate={false}
              defaultRoomTypeImage={defaultRoomTypeImage}
              handleItemSelected={handleItemSelected}
              isReservationGroupBooking={true}
            ></RoomTypeItem>
          ))}
      </div>
      <CheckOutBar isReservationGroupBooking={true} setLoading={() => {}}></CheckOutBar>
    </div>
  );
};

export default React.memo(ReservationGroupInfor);
