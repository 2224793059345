import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';
import { Button, Form, Input, Message } from 'semantic-ui-react';
import Colors from '@/constants/colors';
import Breakpoints from '@/constants/breakpoints';
import { useParams } from 'react-router-dom';
import { login } from '@/api/groupBooking';

const useStyles = createUseStyles(
  {
    root: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    content: {
      width: 500,
      padding: '3rem',
      margin: '2rem',
      marginBottom: '0px',
      marginTop: '100px',
      border: `1px solid ${Colors.grayTwo}`,
      borderRadius: 4,
    },
    [`@media (max-width: ${Breakpoints.md}px)`]: {
      content: {
        width: '100%',
      },
    },
    logo: {
      backgroundImage: "url('/assets/dark_logo.png')",
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '50%',
      height: 70,
      marginBottom: '2rem',
    },
    submit: {
      width: '100%',
      color: `${Colors.whiteOne} !important`,
      background: `${Colors.greenOne} !important`,
    },
    forgotPassword: {
      display: 'block',
      cursor: 'pointer',
      textAlign: 'center',
      margin: '1rem 0',
    },
  },
  { name: 'LoginView' },
);

const LoginView = ({ onLoginSuccess }) => {
  const classes = useStyles();
  const [errors, setErrors] = useState({ email: null, password: null });
  const [failed, setFailed] = useState(false);
  const [credentials, setCredentials] = useState({
    accessCode: '',
  });
  const [loading, setLoading] = useState(false);
  const params = useParams();

  const { accessCode } = credentials;

  const onSubmit = () => {
    setLoading(true);
    setFailed(false);
    login({
      friendlyUrl: params.friendlyUrl,
      groupUrl: params.groupUrl,
      accessCode: credentials.accessCode,
    })
      .then((response) => {
        onLoginSuccess(response.data);
      })
      .catch(() => {
        onError();
      });
  };

  const onError = () => {
    setLoading(false);
    setFailed(true);
  };

  const onChange = (_event, { id, value }) => {
    setErrors({ ...errors, [id]: null });
    setCredentials({ ...credentials, [id]: value.trim() });
  };

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <div className={classes.logo} />
        <Form loading={loading} onSubmit={onSubmit}>
          <Form.Field
            id="accessCode"
            control={Input}
            placeholder="Access Code"
            type="password"
            value={accessCode}
            onChange={onChange}
            error={errors.accessCode}
          />
          <Message
            header="Invalid Credentials"
            content="Please, enter the correct access code."
            visible={!!failed}
            error
          />
          <Button className={classes.submit} type="submit">
            Submit
          </Button>
        </Form>
      </div>
    </div>
  );
};

export default React.memo(LoginView);
