import { round } from 'lodash';

const findItemByRoomType = (itemList, bookingSearchByDatesResponseItem) => {
  var isInCart = undefined;
  itemList.forEach((item) => {
    if (item.roomType.id == bookingSearchByDatesResponseItem.roomType.id) {
      isInCart = item;
    }
  });
  return isInCart;
};

const findCartProperty = (cart, isReservationGroupBooking = false) => {
  var cartProperty = undefined;
  let itemList = !isReservationGroupBooking ? cart.items : cart.reservationGroupItems;
  if (itemList && itemList.length > 0) {
    cartProperty = itemList[0].roomType.property;
  }
  return cartProperty;
};

const cartSummary = (cart, isReservationGroupBooking = false) => {
  let roomQty = 0;
  let total = 0;
  let due = 0;
  let giftCard = cart.giftCard != null ? cart.giftCard.amount : 0;
  let giftCardAmountApplied = 0;

  if (!isReservationGroupBooking) {
    cart.items.forEach((item) => {
      roomQty += item.numberOfRooms;
      total += item.chargesAndTaxes.totalCharges * item.numberOfRooms;
      due += item.chargesAndTaxes.depositAmount * item.numberOfRooms;
    });
  } else {
    cart.reservationGroupItems.forEach((item) => {
      roomQty += item.numberOfRooms;
      total += item.chargesAndTaxes.totalCharges * item.numberOfRooms;
      due += item.chargesAndTaxes.depositAmount * item.numberOfRooms;
    });
  }

  if (cart.giftCard != null){
    if (due <= cart.giftCard.amount){
      due = 0;
      giftCardAmountApplied = total;
    } else{
      due = due - cart.giftCard.amount;
      giftCardAmountApplied = cart.giftCard.amount;
    }
  }
  return {
    roomQty: round(roomQty, 2),
    total: round(total, 2).toFixed(2),
    due: round(due, 2).toFixed(2),
    giftCard: giftCard,
    giftCardAmountApplied: giftCardAmountApplied
  };
};

const isCustomerValid = (customer) => {
  const { firstName, lastName, phoneNumber, country, city, address, email } = customer;
  return !(!firstName || !lastName || !phoneNumber || !country || !city || !address || !email);
};

const isPaymentValid = (payment) => {
  /*const { nameOnCard, cardNumber, expirationMonth, expirationYear, cvv, postalCode } = payment;
  return !(
    !nameOnCard ||
    !cardNumber ||
    !expirationMonth ||
    !expirationYear ||
    !cvv ||
    !postalCode
  );*/
  return payment.paymentFulfilled;
};

export default {
  findItemByRoomType,
  cartSummary,
  isCustomerValid,
  isPaymentValid,
  findCartProperty,
};
