import React, { useState, useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import { Grid, GridColumn } from 'semantic-ui-react';
import { Dimmer, Loader } from 'semantic-ui-react';
import Colors from '@/constants/colors';
import CheckoutPanel from '@/components/checkout/CheckoutPanel';
import LeftCheckoutPanel from '@/components/checkout/LeftCheckoutPanel';
import RightCheckoutPanel from '@/components/checkout/RightCheckoutPanel';
import { Elements } from '@stripe/react-stripe-js';
import { cartSelector } from '@/store/selector';
import { searchSelector } from '@/store/selector';
import { setCartError, addItems, addReservationGroupItems } from '@/components/cartSlice';
import CartHelper from '@/helpers/cartHelper';
import { getStripeCredentials } from '@/api/stripeAPI';
import { cartAPI, groupBookingCartAPI } from '@/api/reservationAPI';
import { loadStripe } from '@stripe/stripe-js';
import { useDispatch } from 'react-redux';
import '../common/Search.css';
import classnames from 'classnames';
import _ from 'lodash';

const useStyles = createUseStyles(
  {
    checkoutPanel: {
      position: 'fixed',
      width: '100% !important',
      height: '100%',
      backgroundColor: Colors.whiteOne,
      bottom: '75px',
      left: '0',
      transform: 'translateY(100%)',
      transition: 'transform 1s ease-in',
      zIndex: 9999999,
      overflow: 'scroll',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
      display: 'none',
    },
    checkoutPanelShowed: {
      composes: ['$checkoutPanel'],
      display: 'block',
    },
    checkoutPanelActive: {
      composes: ['$checkoutPanel'],
      transform: 'translateY(calc(0% + 75px)) !important',
      display: 'block',
    },
    checkoutPanelTop: {
      width: '100%',
      height: '75px',
      backgroundColor: '#000',
      color: '#fff',
      textAlign: 'center',
      padding: '25px',
      cursor: 'pointer',
      '-webkit-transition':
        'opacity 400ms ease-in-out, background-color 400ms ease-in-out, color 400ms ease-in-out, border-color 400ms ease-in-out',
      transition:
        'opacity 400ms ease-in-out, background-color 400ms ease-in-out, color 400ms ease-in-out, border-color 400ms ease-in-out',

      '&.error': {
        backgroundColor: '#d0011b',
        color: Colors.whiteOne,
      },
    },
    checkoutBack: {
      display: 'none',
    },
    checkoutBackActive: {
      composes: ['$checkoutBack'],
      display: 'block',
    },
  },
  { name: 'CheckOutBar' },
);
const CheckOutBar = ({ setLoading, isReservationGroupBooking = false }) => {
  const classes = useStyles();
  const cart = cartSelector();
  const search = searchSelector();
  const dispatch = useDispatch();

  const { total } = CartHelper.cartSummary(cart, isReservationGroupBooking);
  const [stripePromise, setStripePromise] = useState(null);
  const [openCheckout, setOpenCheckout] = useState(false);
  const [loadingCart, setLoadingCart] = useState(false);
  const [numberOfGuestIncorrect, setNumberOfGuestIncorrect] = useState(false);

  const handleLoading = () => {
    setLoading(false);
  };

  const onStripeCredentialError = (error) => {
    dispatch(setCartError({ message: error.response.data }));
  };

  useEffect(() => {
    if (openCheckout) {
      dispatch(setCartError({ message: null }));
      let property = CartHelper.findCartProperty(cart, isReservationGroupBooking);
      if (property) {
        getStripeCredentials(property.id)
          .then((res) => {
            let stripeCredentials = res.data;
            setStripePromise(loadStripe(stripeCredentials.publicKey));
          })
          .catch(onStripeCredentialError);
      }
      if (document.readyState === 'complete') {
        handleLoading();
      } else {
        window.addEventListener('load', handleLoading, { capture: false, passive: true });
        return () => {
          window.removeEventListener('load', handleLoading);
        };
      }
    }
  }, [openCheckout]);

  useEffect(() => {
    setNumberOfGuestIncorrect(false);
    if (openCheckout) {
      if (cart.storedSleeps < search.people) {
        setNumberOfGuestIncorrect(true);
        setOpenCheckout(false);
      }
    }
  }, [cart.items]);

  const openCheckoutDelay = _.debounce(
    () => {
      setLoadingCart(false);
      setOpenCheckout(true);
    },
    3000,
    {
      leading: false,
    },
  );

  const openCheckoutAction = () => {
    if (openCheckout) {
      return;
    }
    if (isReservationGroupBooking) {
      /* WP-1513 - Build checkout items based on people distribution */
      setLoadingCart(true);
      groupBookingCartAPI({
        items: cart.reservationGroupItems,
      })
        .then((res) => {
          if (res.data?.itemList) {
            dispatch(addReservationGroupItems(res.data.itemList));
          }
          openCheckoutDelay();
        })
        .catch((error) => {
          console.log(error);
        });
    } else if (cart.storedSleeps < search.people) {
      setNumberOfGuestIncorrect(true);
    } else {
      /* WP-1513 - Build checkout items based on people distribution */
      setLoadingCart(true);
      cartAPI(
        {
          propertyId: CartHelper.findCartProperty(cart, false).id,
          items: cart.items,
        },
        search.people,
      )
        .then((res) => {
          if (res.data?.itemList) {
            dispatch(addItems(res.data.itemList));
          }
          openCheckoutDelay();
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  return (
    <div
      className={
        openCheckout
          ? classes.checkoutPanelActive
          : (!isReservationGroupBooking && cart.items !== null && cart.items.length > 0) ||
            (isReservationGroupBooking &&
              cart.reservationGroupItems !== null &&
              cart.reservationGroupItems.length > 0)
          ? classes.checkoutPanelShowed
          : classes.checkoutPanel
      }
    >
      <div className={classnames(classes.checkoutPanelTop, numberOfGuestIncorrect ? 'error' : '')}>
        <Grid columns="equal">
          <GridColumn textAlign="left" onClick={() => setOpenCheckout(false)}>
            <div className={openCheckout ? classes.checkoutBackActive : classes.checkoutBack}>
              <i className="arrow left icon"></i> Back
            </div>
          </GridColumn>
          <GridColumn textAlign="center" onClick={openCheckoutAction}>
            <Dimmer.Dimmable>
              <Dimmer active={loadingCart}>
                <Loader />
              </Dimmer>
            </Dimmer.Dimmable>
            {!loadingCart
              ? numberOfGuestIncorrect && !isReservationGroupBooking
                ? 'Please add an additional room to accommodate all your guests.'
                : 'Checkout'
              : ''}
          </GridColumn>
          <GridColumn textAlign="right">
            <span>${total}</span> <i className="cart icon cart-icon"></i>
          </GridColumn>
        </Grid>
      </div>
      <div className="check-out-grid-content">
        <Elements stripe={stripePromise}>
          <CheckoutPanel
            leftPanel={<LeftCheckoutPanel isReservationGroupBooking={isReservationGroupBooking} />}
            rightPanel={
              <RightCheckoutPanel isReservationGroupBooking={isReservationGroupBooking} />
            }
          ></CheckoutPanel>
        </Elements>
      </div>
    </div>
  );
};
export default React.memo(CheckOutBar);
